@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;500;700;900&display=swap');

@import 'prefix';
@import 'locomotive';

$p1:100%;
$p2:50%;

$midblue:darken(#70D6FF,30%);
$midpink:darken(#FF70A6,30%);
$midorange:darken(#FF9770,30%);
$midyellow:darken(#FFD670,30%);
$midlime:darken(#E9FF70,30%);


$darkblue:darken(#70D6FF,80%);
$darkpink:darken(#FF70A6,80%);
$darkorange:darken(#FF9770,80%);
$darkyellow:darken(#FFD670,80%);
$darklime:darken(#E9FF70,80%);

:root {
	--bg-color:#fff;
	--txt-color:#111;
	--white:#fff;
	--blue:#70D6FF;
	--pink:#FF70A6;
	--orange:#FF9770;
	--yellow:#FFD670;
	--lime:#E9FF70;
}

@media (prefers-color-scheme:dark){
	:root {
		--bg-color:#111;
		--txt-color:#fff;
	}
}
@media (prefers-color-scheme:light){
	:root {
		--bg-color:#111;
		--txt-color:#fff;
	}
}

* {
	box-sizing:border-box;
}

html {
	margin:0px auto;
	background:var(--bg-color);
	color:var(--txt-color);
	scroll-behavior: smooth;
	// overflow:hidden;
	@include tablet {
		// overflow:auto;
	}
	body {
		@extend html;
		-webkit-font-smoothing: antialiased;
		touch-action: none;
		-ms-touch-action: none;
		font-family: 'Exo 2', sans-serif;
		@include tablet {
			// overflow: hidden;
		    position: relative;
		    // height: 100%;
		    touch-action: auto;
			-ms-touch-action: auto;		
		}	
	}
}
p {
	font-family: inherit;
}

button,a {
	appearance:none;
	padding: 12px 18px;
    border-radius: 7px;
    background: $midblue;
    color: white;
    font-weight: bold;
    border: 0;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Exo 2', sans-serif;
    cursor: pointer;
    text-decoration: none;
    &.purple {
    	background:#a100d6;
    }
    &.text {
    	padding:0;
    	color:$midblue;
    	text-transform: none;
    	background:transparent;
    	font-size: inherit;
    	font-weight: bold;
    }
}

.container {
	position: relative;
}


.circle-mask {
	width:100vw;
	height:100vh;
	position: absolute;
	top:0;
	left:0;
	z-index:999;
	overflow: hidden;
	--position:50% 50%;
	--size:100px;
  	clip-path:circle(var(--size) at var(--position));
  	&:after {
		content:'';
		position: absolute;
		top:$p2;
		left:$p2;
		width:$p1*2;
		height:$p1*2;
		z-index:-1;
		will-change:transform;
  		background-image: repeating-linear-gradient(
	    	0deg,
	    	var(--blue) 0px,
		    var(--blue) var(--sizeBlue),
		    var(--pink) 60px,
		    var(--pink) 120px,
		    var(--orange) 120px,
		    var(--orange) 180px,
		    var(--yellow) 180px,
		    var(--yellow) 240px,
		    var(--lime) 240px,
		    var(--lime) 300px
		);
		background-repeat: repeat;
		background-size:100% calc(100% + 300px);
		@include transform(translate(-$p2,-$p2) rotate(45deg));
		filter:brightness(0.9) contrast(1.2);
		@include transition(filter .5s ease);
	}
	&.hover {
		&:after {
			animation: stripes 5s linear infinite;
		}
	}
	&.click {
		cursor: pointer;
	}
	&.fixed {
		&:after {
			@include transition(filter 1s .6s ease);
			filter:brightness(3) contrast(1) saturate(0);
		}
	}
}



.section {
	width:100vw;
	height: calc(var(--vh, 1vh) * 100);
	margin:auto;
	position:relative;
	z-index:1;
	&[mode="crazy"]{
		.letters {
			text-shadow: 0px 0px 20px rgba(0,0,0,.2);
			.blue {
				color:var(--white);
			}
			.pink {
				color:var(--white);
			}
			.orange {
				color:var(--white);
			}
			.yellow {
				color:var(--white);
			}
			.lime {
				color:var(--white);
			}
		}
	}
} 

.work {
	height:100vh;
	.video {
		position: absolute;
		top:$p2;
		left:0;
		width:100vw;
		height:150vh;
		background: black;
		@include transform(translateY(-$p2));
		opacity:0;
		@include transition(opacity .2s .4s ease);
		video {
			width:$p1;
			height:$p1;
			object-fit: cover;
			opacity:0.5;
		}
		&.shown {
			opacity:1;
			@include transition(none);
		}
	}
}

.portfolio {
	height:50vh;
	@include tablet {
		height:100vh;		
	}
}

.contact {
	height:100vh;
}


.text-intro {
	width:$p1;
	height:$p1;
	max-width:1200px;
	margin:0 auto;
	@include flex;
	justify-content: center;
	align-items: center;
	position:relative;
	padding:0 30px;
	z-index:1;
	flex-direction: column;
	h1 {
		width:$p1;
		font-size:12vmin;
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing:-1.4vmin;
		margin:0;
		line-height:.7;
		@include tablet {
			font-size:60px;	
			font-size:16vw;	
			line-height:1;
		}
	}
	@include tablet {
		padding:0 20px;		
	}
}
.letters {
	span {
		will-change: transform;
		display:inline-block;
	}
	.line {
		padding-bottom:5vmin;
		margin-bottom:-5vmin;	
	}
	.blue {
		color:var(--blue);
		text-shadow: 0px 0px 20px $darkblue;
	}
	.pink {
		color:var(--pink);
		text-shadow: 0px 0px 20px $darkpink;
	}
	.orange {
		color:var(--orange);
		text-shadow: 0px 0px 20px $darkorange;
	}
	.yellow {
		color:var(--yellow);
		text-shadow: 0px 0px 20px $darkyellow;
	}
	.lime {
		color:var(--lime);
		text-shadow: 0px 0px 20px $darklime;
	}
	.word {
		padding-right:4vmin;
	}
	.char {
		// opacity: 0;
		// @include transform(translateX(-40%));
		// @include transition(transform 1s ease,opacity .4s ease);
	}
	&.show {
		.char {
			// opacity: 1;
			// @include transform(translateX(0));
		}
	}
}

.fixed {
	.letters {
		span.word {
			opacity:0;
			@include transition(opacity .3s ease-in-out);
		}
	}
}

// stars
.stars {
	width:$p1;
	height:$p1;
	position: absolute;
	top:0;
	left:0;
	z-index:0;
	canvas {
		width:$p1;
		height:$p1;

	}
}

// showcase

.showcase-wrapper {
	position: fixed;
	top:0;
	left:0;
	z-index:999;
	width:$p1;
	height:$p1;
	margin:0;
	overflow-x:hidden;
	overflow-y:auto;
	touch-action: auto;
	-ms-touch-action: auto;
	visibility: hidden;
	background:white;
	opacity:0;
	@include transition(visibility .4s ease-in-out,opacity .4s ease-in-out);
	.showcase {
		width:$p1;
		min-height:100vh;
		.close {
		    color: black;
		    text-align: right;
		    font-size: 70px;
		    font-family: 'Exo 2', sans-serif;
		    cursor: pointer;
		    position: absolute;
		    top:0;
		    left:$p2;
		    z-index:999;
		    width:$p1;
			max-width:1200px;
			margin:30px auto;
			padding:0 36px;
			@include transform(translateX(-$p2));
			@include flex;
			justify-content: flex-end;
		    span {
	    	    display: block;
			    height: 40px;
			    line-height: .4;
			    color:white;
		    }
		    @include tablet {
		    	font-size:50px;		
		    	margin:10px;
		    }
		}
		.showcase-items {
			cursor: default;
			.showcase-item {
				.showcase-video {
					width:$p1;
					height:calc(100vh - 322px);
					min-height:500px;
					background:black;
					@include tablet {
						height:auto;
						min-height:unset;		
					}
					video {
						opacity:0.8;
						object-fit: contain;
						width:$p1;
						height:$p1;
						@include tablet {
							height:auto;		
						}
					}
					&.bottom {
						video {
							// object-position: right bottom;
						}
					}
				}
				.showcase-inner {
					width:$p1;
					max-width:1200px;
					margin:42px auto;
					padding:0 36px;
					@include tablet {
						margin:24px auto;
						padding:0 24px;		
					}
					.header {
						@include flex;
						justify-content: space-between;
						align-items:flex-start;
						.actions {
							margin-block-start:10px;
							button,a {
								& + button,
								& + a {
									margin-inline-start:10px;
								}
							}
						}
						@include tablet {
							flex-direction: column;		
						}
					}
					h1 {
						color:rgba(0,0,0,.8);
						margin:0;
					}
					h2 {
						font-size:18px;
						color:rgba(0,0,0,.8);
						font-weight:bold;
						text-transform: uppercase;
						margin:0;
					}
					h3 {
						margin-block-start:16px;
						color:rgba(0,0,0,.6);
						font-weight:400;
						font-size:18px;
					}
					h4 {
						margin-block-start:12px;
						color:rgba(0,0,0,.6);
						font-weight:400;
						font-size:18px;
						line-height:1.4;
					}
					.table {
						@include flex;
						flex-direction: column;
						width:$p1;
						margin-block-start:32px;
						.row {
							@include flex;
							flex-direction: row;
							width:$p1;
							justify-content: flex-start;
							@include tablet {
								flex-direction: column;		
							}
							.item {
								flex-basis:50%;
								flex-shrink:1;
								& + .item {
									margin-inline-start:5%;
									@include tablet {
										margin-inline-start:0;		
									}
								}
							}
							& + .row {
								margin-block-start:24px;
							}
						}
					}
				}
			}
		}
	}
	&.active {
		visibility: visible;
		opacity:1;
		@include transition(visibility .4s 1s ease-in-out,opacity .4s 1s ease-in-out);
		@include tablet {
			@include transition(visibility .4s ease-in-out,opacity .4s ease-in-out);
		}
	}
}

.badge {
	position: fixed;
    background: #444;
    z-index: 999;
    width: calc(100% - 40px);
    border-radius: 10px;
    left: 50%;
    top: 0;
    padding:10px;
    @include transform(translate(-50%,-150%));
    @include transition(transform .8s ease-in-out);
    &.shown {
    	@include transform(translate(-50%,0%));
    }
    p {
    	margin:0;
    }
}



// animations

@keyframes stripes {
	from {
		background-position-y: 0px;
	}
	to { 
		background-position-y:-300px;
	}
}






